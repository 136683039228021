export const CREATE_PAYMENT_LAMBDA_URL = "https://7pohpk55j7nf44llvcrgbt247y0fqwgi.lambda-url.eu-west-3.on.aws/"; // fomento-create-payment
export const TEST_CONFIRM_PAYMENT_URL = "https://api.eeltesting.com/license-activation" ; // fomento-confirm-payment
export const CONFIRM_PAYMENT_URL = "https://api.myexamslab.com/license-activation" ; // fomento-confirm-payment

export const TEST_CREATE_PAYMENT_LAMBDA_URL = "https://cwj2ww4tek4v7g5enuetbuxhb40iwuuu.lambda-url.eu-west-3.on.aws/"; // test-fomento-payment

// Write here the uuids corresponding to the school in EEL
export const SCHOOL_IDS = {
    "ahlzahir":             "a212d0ff-8c04-4a2e-9757-d4b7b914bb42",
    "aitana":               "acc31293-7211-417b-b202-625b06f2c687",
    "aldeafuente":          "55b7b62c-52e7-4018-bf0f-fc97a3ef2589",
    "aldovea":              "93fff7b9-8d34-49b4-99be-dcd04bee3337",
    "altozano":             "759b63f5-1d78-4b12-a79d-237888a086c4",
    // canigo: "", // no EEL
    "el-encinar":           "3928675f-7384-4023-96a8-5df354269042",
    "el-prado":             "f425b2ca-d818-458b-b78c-1db0be0e7a8c",
    "el-vedat":             "ce5d4923-903c-4dd2-b138-5ac33f925e04",
    "entreolivos":          "f228f7a5-70e3-48d3-b57d-6dba3db425bf",
    "fomento":              "3f8ea918-fa99-4842-a5a1-f43918d54630",
    // lasacacias: "", // no EEL
    "las-tablas":           "d2c1762e-8905-4430-9348-734486b199ef",
    "los-olmos":            "a4db2262-b4cf-4587-aa17-4fc9f3b3a55a",
    "los-robles":           "d16a97b0-62cc-4843-85a5-12629d00060b",
    "miralvent":            "4ae90ded-f989-45e6-a573-b7cdbd6a0790",
    "miravalles":           "846dd25f-302e-4ab7-830f-4d745327161f",
    "monteagudo":           "6a919679-b730-426b-b6af-30e78203b766",
    "montealto":            "8816c6b9-02e8-4004-ae3a-ea9bab993e46",
    "montearagon":          "4de4fd15-259e-4715-98f9-58c7053e3df5",
    "montecastelo":         "f05c1c12-7b01-477e-ac28-281374234721",
    "montespino":           "bd5999c1-f829-4a49-82b7-9ad6a5b00913",
    "nelva":                "9bc0d670-c875-4508-a06e-0a9952e75510",
    "penalba" :             "6ad60a96-6198-40d9-ac76-edd0bf2294fe",
    "penamayor" :           "2d2c0002-4550-4e9e-911c-ce0a15f976f7",
    "penarredonda":         "e94bd4c0-7fdd-4279-b7d8-27966be5ffca",
    "pinoalbar":            "2a005cc5-216c-4193-ac6d-de5a67d7b24a",
    "redin":                "da0dc01d-7786-4776-ba80-a776a147366b",
    "sansuena":             "c5bcaeeb-b14b-4b64-b116-5b4e4c49cf5f",
    "tabladilla":           "b4fda5d6-2bcb-43f4-adcd-d51855b1bba3",
    "torrenova":            "223a4273-1e50-4879-a3a8-75cdae3e68f2",
    "torrevelo-penalabra":  "73202266-e4c2-485f-883c-83a48fdbcd4b",
    "valverde":             "2b56c429-8b79-42b7-afcb-06ede3be68b1",
    "vilavella":            "518fe04b-114f-497c-bf61-c58ee8d612f8"
}
