import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import img from "./imgs/img.png";
import fomento from "./logos-coles/fomento.png";

const UserData = () => {
  const [searchParams] = useSearchParams();
  const school = searchParams.get("school");
  const images = require.context("./logos-coles", true);
  const schoolImage = images(`./${school}.png`);
  const [name, setName] = useState("");
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastNameErrorMessage, setLastNameErrorMessage] = useState("");
  const [dni, setDni] = useState("");
  const [dniErrorMessage, setDniErrorMessage] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneErrorMessage, setPhoneErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [postalCodeErrorMessage, setPostalCodeErrorMessage] = useState("");
  const [address, setAddress] = useState("");
  const [addressErrorMessage, setAddressErrorMessage] = useState("");
  const [city, setCity] = useState("");
  const [cityErrorMessage, setCityErrorMessage] = useState("");
  const [fullStudentName, setFullStudentName] = useState("");
  const [fullStudentNameErrorMessage, setFullStudentNameErrorMessage] = useState("");
  const [studentEmail, setStudentEmail] = useState("");
  const [studentEmailErrorMessage, setStudentEmailErrorMessage] = useState("");
  const navigate = useNavigate();
  const fomentoStudentEmailPattern = /^\S+@alumno.fomento.edu$/;

  const onDataChange = (data, setFunction) => {
    setFunction(data);
  };

  const onSubmit = () => {
    let allCorrect = true;
    if (name === "") {
      setNameErrorMessage("Introduzca su nombre");
      allCorrect = false;
    }
    if (lastName === "") {
      setLastNameErrorMessage("Introduzca sus apellidos");
      allCorrect = false;
    }
    if (dni === "") {
      setDniErrorMessage("Introduzca su DNI");
      allCorrect = false;
    }
    if (phoneNumber === "") {
      setPhoneErrorMessage("Introduzca su teléfono");
      allCorrect = false;
    }
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setEmailErrorMessage("Formato de email inválido");
      allCorrect = false;
    }
    if (postalCode === "") {
      setPostalCodeErrorMessage("Introduzca su código postal");
      allCorrect = false;
    }
    if (address === "") {
      setAddressErrorMessage("Introduzca su dirección");
      allCorrect = false;
    }
    if (city === "") {
      setCityErrorMessage("Introduzca su ciudad");
      allCorrect = false;
    }
    if (fullStudentName === "") {
      setFullStudentNameErrorMessage("Introduzca el nombre completo del alumno");
      allCorrect = false;
    }
    if (!fomentoStudentEmailPattern.test(studentEmail)) {
      setStudentEmailErrorMessage("Formato de email incorrecto. Recuerde seguir el formato email@alumno.fomento.edu");
      allCorrect = false;
    }
    if (allCorrect) {
      const userData = {
        name: name,
        lastName: lastName,
        dni: dni,
        phoneNumber: phoneNumber,
        email: email,
        postalCode: postalCode,
        address: address,
        city: city,
        school: school,
        fullStudentName: fullStudentName,
        studentEmail: studentEmail,
      };
      navigate("/checkout", {
        state: { userData: userData },
      });
    }
  };

  return (
    <div className="fomento">
      <style type="text/css">
        {`
        body {
            margin: 0;
            padding: 0;
            font-family: Avenir,Helvetica,Arial,sans-serif;
            color: #2c3e50;
            background-color: #fff;
            float: left;
            width: 100%;
        }
        h1 {
            text-align: center;
            font-size: 38px;
            margin: 10px 0;
        }
        .num {
            display: inline-block;
            width:50px;
            height: 50px;
            -webkit-border-radius: 50px;
            -moz-border-radius: 50px;
            border-radius: 50px;
            background-color:#a5c2c6;
            line-height: 50px;
            text-align: center;
            margin-right: 20px;
        }
        .fomento-logo {display: block; width:100%; float: left; text-align: center; margin-top: 15px;}
        .eel-title {display: block; width:100%; float: left; text-align: center;}
        .first-step {display: block; width:100%; float: left; text-align: center;}
        .first-step p {font-size: 18px; margin: 0;}
        .schools {display: block; width:80%; float: left; margin: 40px 10%;}
        .school {display: inline-block; width:20%; float: left; text-align: center;}
        .school a {
            display: block;
            text-decoration: none;
            color: #727272;
            font-size: 12px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            border-radius: 30px;
            padding: 10px 0 5px 0;
        }
        .school a:hover {background-color: #c7dfe2}
        .help {display: block;float: left;width: 100%; margin: 0 0 20px 0 ; text-align: center;}
        .actions {
            display: block; width:100%; float: left; text-align: center; margin-top: 15px;
        }
        .image {
            display: block; width: 45%; float: left; border-right: 1px solid #ccc; min-height: 500px; 
        }
        .image img {
            display: block;
            width:450px;
            background-color: blue;
            float: right;
            margin-right: 10%;
        }
        .steps {
            display: block; width: 48%; float: left; text-align: left; margin-left: 2%;
        }
        .steps p {font-size: 18px; margin: 0 0 10px 0;}
        .btn-comprar {
            text-align: center;
            background-color: green;
            font-size: 20px;
            text-decoration: none;
            color: white;
            padding: 15px 120px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            border-radius: 30px;
            margin-top: 30px;
            display: block;
            width: 100%;
        }
        .btn-comprar-disabled {
            text-align: center;
            background-color: #A9A9A9;
            font-size: 20px;
            text-decoration: none;
            color: white;
            padding: 15px 120px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            border-radius: 30px;
            margin-top: 30px;
            display: block;
            width: 100%;
            cursor: not-allowed;
            pointer-events: none;
            opacity: 0.5;
        }
        .second-step input, textarea {
            display: inline- block;
            font-size: 14px;
            padding: 10px;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            border-radius: 10px;
            margin-bottom: 10px;
            border: 1px solid #ccc;
            margin-left: 10px;
        }
        .w170 {
            width: 160px;
        }
        .w350 {
            width: 350px;
        }
        .third-step {
            float: left;
            display: block;
            margin-top: 20px;
        }
        .third-step input {
            display: block;
            font-size: 16px;
            padding: 15px;
            width: 350px;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            border-radius: 20px;
            margin-bottom: 10px;
            border: 1px solid #ccc;
            margin-left: 70px;
        }
        .inpts {
            display: block;
            width: 100%;
            float: left;
            margin-left: 60px;
            margin-bottom: 10px; 
        }
        .error-message {
            color: red;         
        }
`}
      </style>

      <div className="fomento-logo">
        <img src={fomento} height="45" width="" alt="fomento" />
        <img src={schoolImage} height="45" alt="school" />
      </div>
      <div className="eel-title">
        <h1>English Exams Lab</h1>
      </div>
      <div className="actions">
        <div className="image">
          <img src={img} alt="license price" className="image" />
        </div>

        <div className="steps">
          <div className="second-step">
            <p>
              <span className="num">2</span> Introduzca los datos del/a <b>comprador/a</b>:
            </p>
            <div className="inpts">
              <input type="text" className="w170" name="name" placeholder="Nombre*" value={name} onInput={(e) => onDataChange(e.target.value, setName)} />
              {nameErrorMessage && <p className="error-message">{nameErrorMessage}</p>}
              <input
                type="text"
                className="w170"
                name="apellidos"
                placeholder="Apellidos*"
                value={lastName}
                onInput={(e) => onDataChange(e.target.value, setLastName)}
              />
              {lastNameErrorMessage && <p className="error-message">{lastNameErrorMessage}</p>}
              <br />
              <input type="text" className="w170" name="dni" placeholder="DNI / NIF / NIE*" value={dni} onInput={(e) => onDataChange(e.target.value, setDni)} />
              {dniErrorMessage && <p className="error-message">{dniErrorMessage}</p>}
              <input
                type="num"
                className="w170"
                name="phone"
                placeholder="Número de teléfono"
                value={phoneNumber}
                onInput={(e) => onDataChange(e.target.value, setPhoneNumber)}
              />
              {phoneErrorMessage && <p className="error-message">{phoneErrorMessage}</p>}
              <br />
              <input type="email" className="w170" name="email" placeholder="Email*" value={email} onInput={(e) => onDataChange(e.target.value, setEmail)} />
              {emailErrorMessage && <p className="error-message">{emailErrorMessage}</p>}
              <input
                type="text"
                className="w170"
                name="postal"
                placeholder="Código Postal*"
                value={postalCode}
                onInput={(e) => onDataChange(e.target.value, setPostalCode)}
              />
              {postalCodeErrorMessage && <p className="error-message">{postalCodeErrorMessage}</p>}
              <br />
              <input
                type="text"
                className="w170"
                name="direccion"
                placeholder="Dirección*"
                value={address}
                onInput={(e) => onDataChange(e.target.value, setAddress)}
              />
              {addressErrorMessage && <p className="error-message">{addressErrorMessage}</p>}
              <input type="text" className="w170" name="ciudad" placeholder="Ciudad*" value={city} onInput={(e) => onDataChange(e.target.value, setCity)} />
              {cityErrorMessage && <p className="error-message">{cityErrorMessage}</p>}
            </div>
          </div>

          <div className="third-step">
            <p>
              <span className="num">3</span>
              Introduzca los datos del/a <b>alumno/a</b>:
            </p>
            <input
              type="text"
              name="name"
              autoComplete="off"
              placeholder="Nombre y Apellidos*"
              value={fullStudentName}
              onInput={(e) => onDataChange(e.target.value, setFullStudentName)}
            />
            {fullStudentNameErrorMessage && <p className="error-message">{fullStudentNameErrorMessage}</p>}
            <input
              type="email"
              name="email"
              autoComplete="off"
              placeholder="email@alumno.fomento.edu*"
              value={studentEmail}
              onInput={(e) => onDataChange(e.target.value, setStudentEmail)}
            />
            {studentEmailErrorMessage && <p className="error-message">{studentEmailErrorMessage}</p>}
            <button
              className="btn-comprar"
              type="submit"
              onClick={() => {
                onSubmit();
              }}
            >
              Finalizar la compra
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserData;
